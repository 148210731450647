var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      !_vm.beat
        ? [
            _c(
              "section",
              {
                staticClass: "section section--fullScreen section--flexCenter",
              },
              [_c("VLoadSpinner")],
              1
            ),
          ]
        : _c("Beat", { attrs: { beat: _vm.beat } }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("h2", { staticClass: "h2 h-mb-30" }, [
                    _vm._v("Related Beats"),
                  ]),
                  _c("PBeatTable", {
                    attrs: { beatPlaylist: _vm.relatedBeats },
                    on: {
                      fetchMoreData: function ($event) {
                        return $event.complete()
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }